"use strict";

import matches from "./matches.js";

// @see https://developer.mozilla.org/ru/docs/Web/API/Element/closest
const closest1 = function (element, selector, context) {
  let node = element;

  while (node) {
    if (context && context === node) {
      return null;
    } else if (matches(node, selector)) {
      return node;
    } else {
      node = node.parentElement;
    }
  }

  return null;
};

// @see http://stackoverflow.com/questions/15329167/closest-ancestor-matching-selector-using-native-dom
const closest2 = function (element, selector, context) {
  const matched = (
    context ||
    element.document ||
    element.ownerDocument
  ).querySelectorAll(selector);

  let i;
  let el = element;

  do {
    i = matched.length;
    while (--i >= 0 && matched.item(i) !== el) {}
  } while (i < 0 && (el = el.parentElement));

  return el;
};

const closest = closest2;

export default closest;
