const win = window;

export default function ({ originalEvent: event }) {
  let text = "";

  if (
    event &&
    event.clipboardData &&
    event.clipboardData.getData &&
    event.clipboardData.types
  ) {
    let types = event.clipboardData.types;

    if (types.contains && types.contains("text/plain")) {
      text = event.clipboardData.getData("text/plain") || "";
    } else if (types.find && types.find((type) => type === "text/plain")) {
      text = event.clipboardData.getData("text/plain") || "";
    }
  }

  if (win.clipboardData && win.clipboardData.getData) {
    text = win.clipboardData.getData("Text") || "";
  }

  return text;
}
