import modules from "ym";
import "../modules/onload";
import "../modules/loader_type_all";
import closest from "../lib/closest";

const removeElement = (element) =>
  element &&
  element.parentNode &&
  element.parentNode.removeChild &&
  element.parentNode.removeChild(element);

const makeElementEmpty = (element) => {
  if (element && element.innerHTML) {
    element.innerHTML = "";
  }
};

const removeBannerSlotC8 = (
  dummy1,
  dummy2,
  dummy3,
  width,
  height,
  element_id
) => {
  [...document.querySelectorAll(`#${element_id}`)].forEach((element) => {
    makeElementEmpty(closest(element, ".banner-slot-head-global"));
    removeElement(closest(element, ".banner-slot"));
  });
};

modules.define(
  "c8_show_code_func",
  ["onload", "loader_type_all"],
  (provide, onload, loader) => {
    const provideDummyFunction = () => {
      provide(removeBannerSlotC8);
    };

    // в c8 походу забили на internet explorer и херячат нерабочий код
    // но мы еще не забили на ie
    // поэтому мы лучше скроем баннеры в ie, чем будем ловить непонятные js ошибки
    if (window.navigator.userAgent.match(/msie|trident/i)) {
      provideDummyFunction();
      return;
    }

    window.c8_sa = window.c8_sa || "";
    window.c8_metric = window.c8_metric || "";
    window.c8_passback = window.c8_passback || [];

    onload(() => {
      loader({
        js: "https://b.c8.net.ua/b/js/sys.js",
        // если не загрузилось, то скорее всего у пользователя adblock :)
        // удаляем баннерный слой (зачем нам пустота?)
        error: provideDummyFunction,
        success() {
          if (typeof show_code != "undefined") {
            provide(window.show_code);
          } else {
            provideDummyFunction();
          }
        },
      });
    });
  }
);

modules.define("c8_show_code", (provide) => {
  const provideBannerSlotRemover = () => {
    provide(removeBannerSlotC8);
  };

  modules.require(["c8_show_code_func"], (show_code) => {
    provide(show_code);
  });
});
