import "./est-lazy-load.scss";

import "core-js/features/array/find";
import $ from "jquery";
import debounce from "lodash/debounce";

let sizes = [240, 256, 320, 480, 512, 640, 800, 1024, 1280];

const qsa = (selector, root = document) => [...root.querySelectorAll(selector)];

const isInView = (element) => {
  const bounds = element.getBoundingClientRect();
  const styles = getComputedStyle(element);
  return (
    bounds.top <= window.innerHeight &&
    bounds.bottom >= 0 &&
    styles.display !== "none"
  );
};

const loadLazy = debounce(() => {
  // конфликтует с vue, поэтому там пока отменяем ленивую загрузку
  qsa(".est-vue .est-lazy-load").forEach((element) => {
    element.classList.remove("est-lazy-load", "est-lazy-load--loading");
  });

  const isDesktop = document.body.offsetWidth > 576;
  const images = qsa(
    ".est-lazy-load[data-src-tpl]:not(.est-lazy-load--loading):not(.est-lazy-load--loaded)"
  );

  images.filter(isInView).forEach((image) => {
    image.classList.add("est-lazy-load--loading");

    const parentWidth = image.parentNode.offsetWidth;
    const width = sizes.find((w) => w >= parentWidth);
    const height = Math.floor(0.7 * width);

    const tpl = image.getAttribute("data-src-tpl");
    const res = isDesktop
      ? tpl
      : tpl.replace(
          /\/(\d+)x(\d+)\/(resize|crop)\//,
          () => `/${width}x${height}/crop/`
        );

    const onLoad = () => {
      image.setAttribute("src", res);
      image.removeAttribute("width");
      image.removeAttribute("height");
      image.classList.remove("est-lazy-load--loading");
      image.classList.add("est-lazy-load--loaded");
    };

    const img = new Image();
    img.onerror = onLoad;
    img.onload = onLoad;
    img.src = res;
  });
}, 10);

document.addEventListener("scroll", loadLazy);
window.addEventListener("resize", loadLazy);
window.addEventListener("orientationchange", loadLazy);

$(document).on("est-lazy-load", loadLazy);
$(document).on("est-dialog-open", loadLazy);

document.addEventListener("DOMContentLoaded", () => {
  loadLazy();
});
