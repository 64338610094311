import $ from "jquery";

const overlay = $("<div/>");

let overlayTimer = null;

$.extend({
  estOverlayShow(additional_classes) {
    overlay.attr("class", "est-overlay");
    overlay.addClass(additional_classes || "");
    overlay.prependTo(document.body);
  },
  estOverlayHide() {
    overlay.detach().attr("class", "");
  },
  shadeAjax() {
    $.estOverlayShow();
    clearTimeout(overlayTimer);
    overlayTimer = setTimeout(() => {
      $.estOverlayShow("est-overlay--loading");
    }, 0);
  },
  shadeAll() {
    $.estOverlayShow();
  },
  unshadeAll() {
    clearTimeout(overlayTimer);
    $.estOverlayHide();
  },
});

$.extend({
  equals(obj1, obj2) {
    for (let i in obj1) {
      if (obj1.hasOwnProperty(i)) {
        if (!obj2.hasOwnProperty(i)) {
          return false;
        }
      }
    }

    for (let i in obj2) {
      if (obj2.hasOwnProperty(i)) {
        if (!obj1.hasOwnProperty(i)) {
          return false;
        }
      }
    }

    for (let i in obj1) {
      if (obj1.hasOwnProperty(i)) {
        switch (typeof obj1[i]) {
          case "object":
            if (!$.equals(obj1[i], obj2[i])) {
              return false;
            }
            break;
          default:
            if (obj1[i] !== obj2[i]) {
              return false;
            }
            break;
        }
      }
    }

    return true;
  },

  intersect(obj1, obj2) {
    return $(obj1).filter($(obj2)).get();
  },

  number_to_fixed(value, precision) {
    precision = precision || 0;

    let power = Math.pow(10, precision);
    let neg = value < 0;

    value = Math.round(value * power);

    let integral = String((neg ? Math.ceil : Math.floor)(value / power));
    let fraction = String((neg ? -value : value) % power);
    let padding = new Array(Math.max(precision - fraction.length, 0) + 1).join(
      "0"
    );

    return precision ? integral + "." + padding + fraction : integral;
  },
});

$.extend($.fn, {
  values(data, withNotify) {
    let inputs = $(this).filter(":input");

    if (arguments.length === 0) {
      let values = {};
      let type;
      let name;
      let value;

      inputs.each(function () {
        if (this.name) {
          type = this.type;
          name = this.name;
          value = $(this).val();
          if (typeof values[name] == "undefined") {
            values[name] = [];
          }
          if (type == "checkbox" || type == "radio") {
            this.checked && values[name].push(value);
          } else {
            $.each($.isArray(value) ? value : [value], function () {
              values[name].push(value);
            });
          }
        }
      });

      return values;
    } else {
      let values = data || {};
      let type;
      let input;
      let value;
      let val_before;
      let val_after;
      let trigger = withNotify || false;

      inputs.each(function () {
        if (this.name) {
          type = this.type;
          input = $(this);
          value = this.name in values ? values[this.name] : [];

          if (type == "checkbox" || type == "radio") {
            val_before = this.checked;
            this.checked = -1 != $.inArray(this.value, value);
            val_after = this.checked;
          } else {
            val_before = input.val();
            input.val(value);
            val_after = input.val();
          }

          if (val_before !== val_after && trigger) {
            input.trigger("change");
          }
        }
      });

      return this;
    }
  },
  attrs() {
    let attributes = {};

    if (this.length) {
      $.each(this[0].attributes, function (index, attr) {
        attributes[attr.name] = attr.value;
      });
    }

    return attributes;
  },
});

$(document).ajaxSend(function (event, jqXHR, options) {
  if (options.overlay) {
    $.data(document, "ajax-send") || $.data(document, "ajax-send", true);
    $.shadeAjax();
  }
});

$(document).ajaxComplete(function (event, jqXHR, options) {
  if (options.overlay) {
    $.data(document, "ajax-send", false);
    $.unshadeAll();
  }
});
