import $ from "jquery";
import scrollTo from "../lib/scrollto";

$(document).on("click", "[data-scroll-to]", function (e) {
  const cur_href = location.href.replace(/#.*$/, "");
  const res_href = (this.getAttribute("href") || "").replace(/#.*$/, "");
  const selector = this.getAttribute("data-scroll-to");

  const is_ok =
    document.querySelector(selector) && (!res_href || res_href === cur_href);

  if (is_ok) {
    e.preventDefault();

    const distance = parseInt(this.getAttribute("data-scroll-distance"));
    const duration = parseInt(this.getAttribute("data-scroll-duration")) || 100;

    scrollTo(document, selector, {
      offset: { top: distance, left: 0 },
      duration: duration,
      axis: "y",
    });
  }
});
