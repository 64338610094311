import "./comment-form.scss";

import $ from "jquery";
import "jquery-form";
import hash from "../../lib/hash";
import storage from "../../lib/storage";

$(document).on("click", ".comment-form-toggler", function () {
  const form = $(this).next("form");

  hash.replace();

  if (form.is(":visible")) {
    form.addClass("comment-form--hidden");
    form.addClass("comment-form--hidden-force");
  } else {
    form.removeClass("comment-form--hidden");
    form.removeClass("comment-form--hidden-force");
  }
});

$(document).on("input keypress", ".comment-form", function () {
  $(this).find(".comment-form__errors").empty();
});

$(document).on("submit", ".comment-form", function (event) {
  const form = $(this);
  const textarea = form.find("textarea");
  const button = form.find("button");

  textarea.attr("readonly", "readonly");
  button.attr("disabled", "disabled").addClass("ui-button--loading");

  form.ajaxSubmit({
    dataType: "jsonp",
    data: {
      sf_format: "jsonp",
    },
    url: form.attr("data-url"),
    error: function () {
      textarea.removeAttr("readonly");
      button.removeAttr("disabled").removeClass("ui-button--loading");
      textarea.focus();
    },
    success: function (data) {
      const item_current =
        data && data.id ? $(`.comment-item[data-comment-id="${data.id}"]`) : [];
      const item_parent = form.closest(".comment-item");
      const flow_footer = form.closest(".flow-panel__foot");
      const comment_block = form.closest(".comment-block");
      const comment_block_title = comment_block.find(".comment-block__title");
      const errors = [];

      $.each(data.errors || {}, function (field, message) {
        errors.push(message);
      });

      form.find(".comment-form__errors").html(errors.join("<br />"));

      textarea.val(errors.length ? textarea.val() : "");
      textarea.removeAttr("readonly");
      textarea.focus();

      button.removeAttr("disabled");
      button.removeClass("ui-button--loading");

      if (data.html) {
        const storage_key = "comment-" + textarea.attr("data-tree-uuid");
        storage.local.getItem(storage_key, function (value) {
          if (value) {
            storage.local.removeItem(storage_key);
            $("html, body").animate(
              {
                scrollTop: $(".comment-form").offset().top,
              },
              2000
            );
          }
        });

        if (item_current.length) {
          item_current.replaceWith(data.html);
        } else if (item_parent.length) {
          item_parent.append(data.html);
          form.remove();
        } else if (flow_footer.length) {
          form.before(data.html);
        } else if (comment_block_title.length) {
          comment_block_title.after(data.html);
        } else if (comment_block.length) {
          form.before(data.html);
          // comment_block.prepend(data.html);
        } else {
          location.reload();
        }
      }
    },
  });

  event.preventDefault();
});

$(document).on("click", ".comment-form__button[data-tree-uuid]", function (e) {
  const tree_uuid = $(e.currentTarget).attr("data-tree-uuid");
  const value = $(`textarea[data-tree-uuid="${tree_uuid}"]`).val();

  if (value) {
    storage.local.setItem("comment-" + tree_uuid, value);
  }
});

$(() => {
  $("textarea[data-tree-uuid]").each(function () {
    const textarea = $(this);
    const storage_key = "comment-" + textarea.attr("data-tree-uuid");

    storage.local.getItem(storage_key, function (value) {
      if (value) {
        textarea.val(value);

        const form = textarea.closest("form.comment-form");
        if (form.length) {
          form.submit();
        }
      }
    });
  });
});
