import "./_vcard-scroller.scss";

import $ from "jquery";
import scrollTo from "../../lib/scrollto";

const $win = $(window);
const scroller = $(".vcard-scroller");

scroller.length &&
  $win.on("scroll", () => {
    scroller.toggleClass("vcard-scroller--opened", $win.scrollTop() > 300);
  });

scroller.length &&
  scroller.on("click", (e) => {
    e.preventDefault();

    scrollTo(window, 0, { duration: 500 });
  });
