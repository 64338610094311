import "./article.scss";

import $ from "jquery";

$(document).on("click", ".article-link-vote", function () {
  const link = $(this);
  const href = link.attr("data-vote-url");
  const article_id = link.attr("data-article-id");

  if (!href) {
    return;
  }

  $(`.article-link-vote--plus[data-article-id="${article_id}"]`).remove();
  $(`.article-link-vote--minus[data-article-id="${article_id}"]`).remove();

  $.ajax({
    url: href,
    data: {
      sf_format: "jsonp",
    },
    dataType: "jsonp",
    success(data) {
      $(`.article-link-vote[data-article-id="${article_id}"]`)
        .attr("data-vote-url", data.vote_url)
        .attr("data-votes-count", parseInt(data.count) || 0);

      $(`.article-votes-count[data-article-id="${article_id}"]`).attr(
        "data-votes-count",
        parseInt(data.count) || 0
      );
      $(`.article-voters[data-article-id="${article_id}"]`).html(
        data.voters_html
      );

      let el_likes = $(".vcard-cabinet-likes");
      if (el_likes.length > 0) {
        $.post(el_likes.attr("data-url"), (html) => el_likes.html(html));
      }
    },
  });
});

/**
 * Если статья неопубликована, то
 * - запрещаем размещение в пресс-центре
 * - запрещаем оповещения по email
 */
(() => {
  const input_article_is_published = $('input[name="article[is_published]"]');
  const input_article_object_id = $('input[name="article[object_id]"]');
  const input_article_notify = $('input[name="article[notify][]"]');

  input_article_is_published.on("change", function () {
    input_article_object_id.prop("disabled", !this.checked);
    input_article_notify.prop("disabled", !this.checked);

    this.checked || input_article_object_id.val([]);
    this.checked || input_article_notify.val([]);
  });

  input_article_is_published.trigger("change");
})();

/**
 * Добавляем или удаляем поля переводов в статьях
 */
(() => {
  const trigger_selector = ".js-toggle-article-translation";

  function setState(trigger, { isOpened }) {
    let form = trigger.closest("form");
    let slug = trigger.attr("data-slug");
    let link_to_google_translate = form.find("[data-google-translate]");
    let input_remove_additional_translate = form.find(
      '[name="_remove_additional_translate"]'
    );
    let fields = form.find(
      `[name="mini-article[body_${slug}]"], #article_name_${slug}, #article_lead_${slug}, #article_body_${slug}`
    );
    let fields_row = fields.closest(
      "._js-article-culture, .est-form-table__row, .sf_admin_form_row"
    );
    let txt = trigger.attr(isOpened ? "data-txt-del" : "data-txt-add");

    trigger.html() === txt || trigger.html(txt);
    input_remove_additional_translate.val(isOpened ? "0" : "1");
    trigger.attr("data-open", isOpened ? "1" : "0");
    trigger.toggleClass("ui-button--green", !isOpened);
    trigger.toggleClass("ui-button--yellow", isOpened);
    link_to_google_translate.toggleClass("hidden", !isOpened);
    fields_row.toggleClass("hidden", !isOpened);
  }

  $(document).on("click", trigger_selector, function () {
    let trigger = $(this);
    let isOpened = trigger.attr("data-open") === "1";
    setState(trigger, { isOpened: !isOpened });
  });

  $(trigger_selector).each(function () {
    let trigger = $(this);
    let isOpened = trigger.attr("data-open") === "1";
    setState(trigger, { isOpened });
  });
})();

/**
 * Кто-нить напишет коммент, что делает этот кусок кода?
 */
if ($(".vcard-body .est-article-form-mini .est-form-div__error").length > 0) {
  $(".est-steps__goto[data-step=3]").trigger("click");
}
