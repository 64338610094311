import "./comment-item.scss";

import $ from "jquery";
import modules from "ym";
import "../../modules/hash";
import confirm from "../../lib/confirm";

const doc = $(document);

doc.on("click", ".comment-item__link-delete", function () {
  const link = $(this);
  const href = link.attr("data-url");
  const comment_id = link.closest(".comment-item").attr("data-comment-id");
  const comment = $(`.comment-item[data-comment-id="${comment_id}"]`);
  const wrapper = comment.children(".comment-item__wrapper");

  confirm("Вы уверены, что хотите удалить комментарий?", {
    success: function () {
      $.ajax({
        url: href,
        data: {
          sf_format: "jsonp",
        },
        dataType: "jsonp",
        success: function (data) {
          if (data.success) {
            comment.addClass("comment-item--deleted");
            wrapper.removeClass("comment-item__wrapper--last");
            wrapper.removeClass("comment-item__wrapper--my");
            wrapper.empty();
          }
        },
      });
    },
  });

  return false;
});

doc.on("click", ".comment-item__link-vote[data-vote-url]", function () {
  const link = $(this);
  const href = link.attr("data-vote-url");
  const comment_id = link.attr("data-comment-id");

  if (href) {
    $.ajax({
      url: href,
      data: {
        sf_format: "jsonp",
      },
      dataType: "jsonp",
      success: function (data) {
        $(`.comment-item__link-vote[data-comment-id="${comment_id}"]`).attr(
          "data-votes-count",
          parseInt(data.count) || 0
        );
      },
    });
  }
});

doc.on(
  "click",
  ".comment-item__link-answer[data-comment-id], .comment-item__link-modify[data-comment-id]",
  function () {
    const url = $(this).attr("data-form-url");
    const is_edit = $(this).is(".comment-item__link-modify");
    // const comment_id = $(this).attr('data-comment-id');
    // const wrapper = $(`.comment-item[data-comment-id="${comment_id}"]`).children('.comment-item__wrapper');
    const wrapper = $(this).closest(".comment-item__wrapper");

    if (is_edit && wrapper.find(".comment-form--edit").length) {
      wrapper.find(".comment-item__forms").html("");
    } else if (!is_edit && wrapper.find(".comment-form--add").length) {
      wrapper.find(".comment-item__forms").html("");
    } else if (url) {
      $.ajax({
        url: url,
        data: {
          sf_format: "jsonp",
        },
        dataType: "jsonp",
        success: function (data) {
          $(".comment-item__forms").empty();
          wrapper.find(".comment-item__forms").html(data.form || "");
          wrapper.find("textarea").focus().click();
        },
      });
    }

    return false;
  }
);

doc.on("click", ".comment-item__link-show-actions", function () {
  $(this)
    .parents(".comment-item__controls")
    .find(".comment-item__actions")
    .toggleClass("opened");
});

// Открытие формы ответа при активации соответствующего url хэша
modules.require(["hash"], function (hash) {
  const hash_change_handler = function () {
    if (hash.getItem("comment-answer")) {
      $(
        '.comment-item__link-answer[data-comment-id="' +
          hash.getItem("comment-answer") +
          '"]:first'
      ).trigger("click");
      hash.removeItem("comment-answer");
    }
    if (hash.getItem("comment-modify")) {
      $(
        '.comment-item__link-modify[data-comment-id="' +
          hash.getItem("comment-modify") +
          '"]:first'
      ).trigger("click");
      hash.removeItem("comment-modify");
    }
  };

  $(window).on("hashchange", hash_change_handler);
  $(hash_change_handler);
});

$(document).on("click", ".comment-item .comment-answer", function (event) {
  $(this).closest(".comment-item").find(".feature-comment-form:first").toggle();

  return false;
});
